import { globalEnvironment } from "./globalEnvironment";
import { labelsCatasia } from "../app/resource/labels";

//const baseURL = "https://catasia.co:8090";
//const baseURL = "https://catasia.catastro-sesquile.com";
const baseURL = "";

export const environment = {
  environment: "sesquile",
  production: true,
  name: "sesquile",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],
  // https://sm.catasia.co
  //GUI
  theme: "green",
  logoLogin: "assets/layout/images/sesquile/login.png",
  loginText: null,
  logoTopBar: "assets/layout/images/sesquile/topbar.png ",
  textTopBar: null,
  logofooterEsri: "assets/layout/images/LogosEsri/Col blanco vertical.png",
  logofooterEsriHeight: "70px",
  logofooterCatasIA: "assets/layout/images/gentemovil/Ver_GenteMovil_banco.png",
  logofooterCatasIAHeight: "70px",
  logoLoginHeight: "300px",
};
