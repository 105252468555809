import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { environment } from "../../environments/environment";
import { ITramiteDocumentoTipo } from "../models/dtos/documento.requerido.model";
import { ParametrosModel } from "../models/parametros.model";
import { PlantillaModel } from "../models/plantilla.model";
import { RoleModel, RolModuloModel } from "../models/roles.model";
import { SolicitanteModel } from "../models/solicitante.model";
import { UsuarioModel } from "../models/usuario.model";
import { AppSettings } from "../util/AppSettings";
import { CatasiaService } from "./catasia-service";
import { PlantillaCorreo } from "../models/dtos/plantilla.correo.model";

@Injectable({
  providedIn: "root",
})
export class AdminService extends CatasiaService {
  constructor(private http: HttpClient) {
    super();
  }

  getUsers() {
    return this.http.get(environment.API_URL + "users", {
      headers: AppSettings.getHeader(),
    });
  }

  getRoles() {
    return this.get(environment.host.ms_gateway.methods.roles, this.http);
  }

  postRol(rol: RoleModel) {
    return this.post(environment.host.ms_gateway.methods.roles, rol, this.http);
  }

  putRol(rol: RoleModel) {
    return this.put(environment.host.ms_gateway.methods.roles, rol, this.http);
  }

  deleteRol(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.roles + `/${id}`,
      this.http
    );
  }

  getRolModuloByMenu(rolId?: number) {
    let query: string = rolId ? `?rolId=${rolId}` : "";
    return this.get(
      environment.host.ms_gateway.methods.rolModuloMenu + query,
      this.http
    );
  }

  postRolModulo(rolModulo: RolModuloModel) {
    return this.post(
      environment.host.ms_gateway.methods.rolModulo,
      rolModulo,
      this.http
    );
  }

  deleteRolModulo(rolModuloId: number) {
    return this.delete(
      environment.host.ms_gateway.methods.rolModulo + "/" + rolModuloId,
      this.http
    );
  }

  getEstadosUsuario() {
    return this.get(
      environment.host.ms_gateway.methods.usuarioEstados,
      this.http
    );
  }

  getDocumentTypes() {
    return this.http.get(environment.API_URL + "documentTypes", {
      headers: AppSettings.getHeader(),
    });
  }

  saveUser(user: any) {
    return this.http.post(environment.API_URL + "users", user, {
      headers: AppSettings.getHeader(),
    });
  }

  getUserInfo(token: string) {
    let bearer_headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    return this.http.get(
      environment.API_URL + environment.host.ms_gateway.methods.getInfo,
      {
        headers: bearer_headers,
      }
    );
  }

  getUsuarios() {
    return this.get(environment.host.ms_gateway.methods.users, this.http);
  }

  getUsuariosGeo() {
    return this.get(environment.host.ms_gateway.methods.usersGeo, this.http);
  }

  getFirmantes() {
    return this.get("usuario/firmantes", this.http);
  }

  getUsuarioById(id: number) {
    return this.get(
      environment.host.ms_gateway.methods.usuarioById + id,
      this.http
    );
  }

  guardarUsuario(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.users,
      body,
      this.http
    );
  }

  actualizarUsuario(body: UsuarioModel) {
    return this.put(environment.host.ms_gateway.methods.users, body, this.http);
  }

  eliminarUsuario(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.users + "/" + id,
      this.http
    );
  }

  getFasesTramite(id: number) {
    return this.get(
      environment.host.ms_gateway.methods.tramiteFase + id,
      this.http
    );
  }

  getFirmaUsuario(id: number) {
    return this.get(
      environment.host.ms_gateway.methods.usuarioFirma + id,
      this.http
    );
  }

  postFirmaUsuario(id: number, body: any) {
    return this.post(
      environment.host.ms_gateway.methods.usuarioFirma + id,
      body,
      this.http
    );
  }

  deleteFirmaUsuario(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.usuarioFirma + id,
      this.http
    );
  }

  postCambiarClaveAdmin(id: number, clave: string) {
    return this.post(
      environment.host.ms_gateway.methods.usuarioCambiarClave +
      `/${id}` +
      `?clave=${clave}`,
      {},
      this.http
    );
  }

  postCambiarClaveUsuario(claveAnterior: string, claveNueva: string) {
    return this.post(
      environment.host.ms_gateway.methods.usuarioCambiarClave +
      `?claveAnterior=${claveAnterior}&claveNueva=${claveNueva}`,
      {},
      this.http
    );
  }

  getPlantillas() {
    return this.get(environment.host.ms_gateway.methods.plantilla, this.http);
  }

  postCambiarPlantilla(id: number, formData: FormData) {
    return this.post(
      environment.host.ms_gateway.methods.plantillaCambiar + id,
      formData,
      this.http
    );
  }

  postCrearPlantilla(plantilla: PlantillaModel) {
    return this.post(
      environment.host.ms_gateway.methods.plantilla,
      plantilla,
      this.http
    );
  }

  putPlantilla(plantilla: PlantillaModel) {
    return this.put(
      environment.host.ms_gateway.methods.plantilla,
      plantilla,
      this.http
    );
  }

  deletePlantilla(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.plantilla + `/${id}`,
      this.http
    );
  }

  cintasTramites(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.cintasTramite,
      body,
      this.http
    );
  }

  cintasResoluciones(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.cintasResoluciones,
      body,
      this.http
    );
  }

  cintasFechas(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.cintasFechas,
      body,
      this.http
    );
  }

  cintasMultiFechas(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.cinstasMultiFechas,
      body,
      this.http
    );
  }

  postProductoPredio(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosPredio,
      body,
      this.http
    );
  }
  postProductoManzana(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosManzana,
      body,
      this.http
    );
  }
  postProductoMunicipio(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosMunicipio,
      body,
      this.http
    );
  }
  postProductoSector(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosSector,
      body,
      this.http
    );
  }
  postProductoZona(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosZona,
      body,
      this.http
    );
  }
  postProductoDocumento(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosDocumento,
      body,
      this.http
    );
  }
  postProductoRangoPredio(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosRangoPredio,
      body,
      this.http
    );
  }
  postProductoRangoManzana(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosRangoManzana,
      body,
      this.http
    );
  }
  postProductoListaManzana(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosListaManzana,
      body,
      this.http
    );
  }
  postProductoListaPredio(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosListaPredio,
      body,
      this.http
    );
  }
  postProductoDescarga(body: any) {
    return this.post(
      environment.host.ms_gateway.methods.productosDescarga,
      body,
      this.http
    );
  }

  getTipoProductos() {
    return this.get(
      environment.host.ms_gateway.methods.tipoProductos,
      this.http
    );
  }
  getTipoConsulta() {
    return this.get(
      environment.host.ms_gateway.methods.tipoConsulta,
      this.http
    );
  }
  getTipoPedido() {
    return this.get(environment.host.ms_gateway.methods.tipoPedido, this.http);
  }

  getEstadosProducto() {
    return this.get(
      environment.host.ms_gateway.methods.estadosProductoR1R2,
      this.http
    );
  }
  getProductosR1R2(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.productoR1R2,
      filter,
      this.http
    );
  }

  getSolicitudesFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.solicitudFiltrar,
      filter,
      this.http
    );
  }

  getParametros() {
    return this.get(environment.host.ms_gateway.methods.parametro, this.http);
  }
  putParametro(parametro: ParametrosModel) {
    return this.put(
      environment.host.ms_gateway.methods.parametro,
      parametro,
      this.http
    );
  }

  getConsecutivos() {
    return this.get(environment.host.ms_gateway.methods.consecutivo, this.http);
  }

  postConsecutivoCambiar(id: number, body: any) {
    return this.post(
      environment.host.ms_gateway.methods.consecutivoCambiar + id,
      body,
      this.http
    );
  }

  getTrazasFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.trazaFiltrar,
      filter,
      this.http
    );
  }

  getTrazaByReferenciaId(tipo: string, id: number) {
    return this.get(
      environment.host.ms_gateway.methods.trazaByReferenciaId + tipo + `/${id}`,
      this.http
    );
  }

  getTipoTraza() {
    return this.get(environment.host.ms_gateway.methods.tipoTraza, this.http);
  }

  postGenerarReporteCSVTramitesFilter(filter?: LazyLoadEvent) {
    return this.postBlob(
      environment.host.ms_gateway.methods.tramiteFiltrarCSV,
      filter,
      this.http
    );
  }
  postGenerarReportePDFTramitesFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteFiltrarPDF,
      filter,
      this.http
    );
  }
  postGenerarReporteXLSXTramitesFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteFiltrarXLSX,
      filter,
      this.http
    );
  }

  getSolicitantesFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.solicitanteFiltrar,
      filter,
      this.http
    );
  }

  putSolicitante(solicitante: SolicitanteModel) {
    return this.put(
      environment.host.ms_gateway.methods.solicitante,
      solicitante,
      this.http
    );
  }

  deleteSolicitante(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.solicitante + `/${id}`,
      this.http
    );
  }

  getTramiteDocumentos() {
    return this.get(
      environment.host.ms_gateway.methods.tramiteDocumento,
      this.http
    );
  }
  postTramiteDocumento(tramiteDocumento: ITramiteDocumentoTipo) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteDocumento,
      tramiteDocumento,
      this.http
    );
  }

  deleteTramiteDocumento(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.tramiteDocumento + `/${id}`,
      this.http
    );
  }

  getPlantillasCorreos() {
    return this.get(
      environment.host.ms_gateway.methods.plantillasCorreo,
      this.http
    );
  }

  putTramiteDocumento(tramiteDocumentoTipo: ITramiteDocumentoTipo) {
    return this.put(
      environment.host.ms_gateway.methods.tramiteDocumento,
      tramiteDocumentoTipo,
      this.http
    );
  }

  putPlantillaCorreo(plantilla: PlantillaCorreo) {
    return this.put(
      environment.host.ms_gateway.methods.plantillasCorreo,
      plantilla,
      this.http
    );
  }

  deletePlantillaCorreo(id: number) {
    return this.delete(
      environment.host.ms_gateway.methods.plantillasCorreo + `/${id}`,
      this.http
    );
  }

  getOficinas() {
    return this.get(
      'oficina',
      this.http
    );
  }

  getOficinasActivas() {
    return this.get(
      'oficina/activo',
      this.http
    );
  }

  postOficina(oficina: any) {
    return this.post(
      'oficina',
      oficina,
      this.http
    );
  }

  putOficina(oficina: any) {
    return this.put(
      'oficina',
      oficina,
      this.http
    );
  }

  deleteOficina(id: number) {
    return this.delete(
      `oficina/${id}`,
      this.http
    );
  }

  getFirmas() {
    return this.get(
      'tipoDocumental/firmantes',
      this.http
    );
  }

  postFirma(id: number, firmanteId: number) {
    let url = 'tipoDocumental/firmante/' + id;
    if (firmanteId) {
      url += "?firmanteId=" + firmanteId;
    }
    return this.post(url, null, this.http);
  }

  //Tramite tipo compatible service

  getTramiteTipoCompatible() {
    return this.get(
      environment.host.ms_gateway.methods.tramiteTipoCompatible,
      this.http
    );
  }

  postTramiteTipoCompatible(tramite: Object) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteTipoCompatible,
      tramite,
      this.http);
  }

  deleteTramiteTipoCompatible(tramite: Object) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteTipoCompatible + '/delete',
      tramite,
      this.http);
  }

  getTramiteTipo() {
    return this.get(
      environment.host.ms_gateway.methods.tramitesTipos,
      this.http
    );
  }

}
