import { Component, OnDestroy } from "@angular/core";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { MenuService } from "../../services/app.menu.service";
import { SecurityService } from "../../services/security.service";

import { EscritorioTramite } from "../../models/dtos/escritorio-tramite-dto";
import { FormaPeticionModel } from "../../models/forma.peticion";
import { PredioModel } from "../../models/predio.model";
import { SistemaEnvioModel } from "../../models/sistema.envio";
import { SolicitanteModel } from "../../models/solicitante.model";
import { SolicitudModel } from "../../models/solicitud";
import { UserInfoModel } from "../../models/user.info.model";

// import * as data from "../../catastro/bd.json";
import * as buildDate from "../../../build.json";

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { AppState } from "src/app/app.reducer";
import { tipoArchivosCompatible } from "src/app/helpers/archivosCompatibles";
import { getFileSizeUpload, getFileSizeUploadParseMB } from "src/app/helpers/sizeFileUpload";
import { ModuloPermisosModel, PermisoInterface } from "src/app/models/modulo.model";
import { AdminService } from "src/app/services/admin.service";
import { BreadcrumbService } from "src/app/services/breadcrumb.service";
import { RadicarService } from "src/app/services/radicar.service";
import { environment } from "src/environments/environment";
import { PropietarioModel } from "../../models/dtos/propietario.model";
import { setUser } from "../../reducers/auth.actions";
import { AppSettings } from "../../util/AppSettings";

@Component({
  selector: "app-main",
  templateUrl: "./app.main.component.html",
  animations: [
    trigger("mask-anim", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      state(
        "visible",
        style({
          opacity: 0.8,
        })
      ),
      transition("* => *", animate("250ms cubic-bezier(0, 0, 0.2, 1)")),
    ]),
  ],
  styleUrls: ["../../resource/validaciones.scss"],
  providers: [SecurityService],
})
export class AppMainComponent implements OnDestroy {
  horizontalMenu: boolean = true;

  darkMode = false;

  menuColorMode = "light";

  menuColor = "layout-menu-light";

  themeColor = "blue";

  layoutColor = "blue";

  rightPanelClick: boolean;

  rightPanelActive: boolean;

  menuClick: boolean;

  staticMenuActive = true;

  menuMobileActive: boolean;

  megaMenuClick: boolean;

  megaMenuActive: boolean;

  megaMenuMobileClick: boolean;

  megaMenuMobileActive: boolean;

  topbarItemClick: boolean;

  topbarMobileMenuClick: boolean;

  topbarMobileMenuActive: boolean;

  configDialogActive: boolean;

  sidebarActive: boolean;

  activeTopbarItem: any;

  topbarMenuActive: boolean;

  menuHoverActive: boolean;

  username: string;

  usuario: UserInfoModel = new UserInfoModel();

  // data = data;
  buildDate = buildDate["default"];

  usuarios: any[];

  usuarioSel: any;

  usuariosFiltrados: any[];

  urlAvatar = "assets/layout/images/user.svg";

  solicitante: SolicitanteModel = new SolicitanteModel();
  selectedFormaPeticion: FormaPeticionModel;
  selectedSistemaEnvio: SistemaEnvioModel;

  solicitud: SolicitudModel = new SolicitudModel();
  notificacionCorreo: boolean;
  referenciaExterna: string;

  tramiteActual: number;
  tramiteId: number;
  tramitePadre: EscritorioTramite;
  tramiteId2: number;
  tramitePadre2: EscritorioTramite;
  prediosUsadosPreviamente: PredioModel[] = [];

  archivoTipos: string;
  filesize: number;

  //REFACTORIZAR TODO LO QUE CAE EN ESTE COMPONENETE EN UNA CAPA DE DATOS COMO REDUX

  propietarios: PropietarioModel[] = [];

  loadingModules: boolean = false;

  subscriptionStore: Subscription;

  contactoSolicitud: any = {};

  oficinas: any[] = [];
  oficinaSelected: any;

  guias = [{
    key: "radicador",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO RADICACION - DIGITALIZACION V 3.0.pdf",
    name: "Guía de usuario del radicador y digitalizador",
    hint: "Guía para crear solicitudes, revisar trámites devueltos y digitalizar"
  }, {
    key: "juridico",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO COORDINADOR JURIDICO.pdf",
    name: "Guía de usuario del coordinador jurídico",
    hint: "Guía para determinar procedencia y validación de trámites"
  }, {
    key: "tecnico",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO COORDINADOR TECNICO V 3.0.pdf",
    name: "Guía de usuario del coordinador técnico",
    hint: "Guía para validación técnica de trámites en el componente administrativo"
  }, {
    key: "asesor",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO ASESOR V 3.0.pdf",
    name: "Guía de usuario del asesor",
    hint: "Guía para devolver radicaciones, asignar trámites y aprobar resoluciones"
  }, {
    key: "secretaria",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO SECRETARIA V 2.1.pdf",
    name: "Guía de usuario de secretaría",
    hint: "Guía para finalizar trámites y archivar"
  }, {
    key: "admin",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO ADMINISTRADOR V 2.1.pdf",
    name: "Guía de usuario del administrador",
    hint: "Guía para la administración del componente administrativo"
  }, {
    key: "buscar",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULOS TRANSVERSALES V 2.0.pdf",
    name: "Guía de usuario módulos transversales",
    hint: "Guía para el manejo del buscador"
  }, {
    key: "ejecutor",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO EJECUTOR V 2.0.pdf",
    name: "Guía de usuario del ejecutor",
    hint: "Guía para la ejecución de trámites en el componente administrativo"
  }, {
    key: "productos",
    url: "GUIA DE USUARIO COMPONENTE ADMINISTRATIVO - MODULO PRODUCTOS CATASTRALES V 2.1.pdf",
    name: "Guía de usuario productos catastrales",
    hint: "Guía para la gestión de certificados y cintas"
  }];

  guiasGeos = {
    ejecutor: [{
      url: "12_Guia Usuario Componente Geografico - Modulo Trabajo Oficina Ejecutor V2.X.pdf",
      name: "Guía de usuario ejecutor geográfico",
      hint: "Módulo trabajo de oficina"
    }, {
      url: "10_Guia Usuario Componente Geografico - Modulo Trabajo Campo Ejecutor V2.X.pdf",
      name: "Guía de usuario ejecutor geográfico I",
      hint: "Módulo trabajo de campo"
    }, {
      url: "11_Guia Usuario Componente Geografico - Modulo Trabajo Campo Ejecutor - Complemento Mutaciones V2.X.pdf",
      name: "Guía de usuario ejecutor geográfico II",
      hint: "Módulo trabajo de campo mutaciones"
    }],
    tecnico: [{
      url: "13_Guia Usuario Componente Geografico - Modulo Trabajo Oficina Coordinador Tecnico V2.X.pdf",
      name: "Guía de usuario geográfica coordinador técnico",
      hint: "Módulo trabajo de oficina"
    }],
    ejecutorYtecnico: [{
      url: "FlujoWFMSGC V2.X.pdf",
      name: "Flujo de trabajo geográfico",
      hint: "Para ejecutores y coordinadores técnicos"
    }, {
      url: "Descripcion_FlujoWFMSGC V2.X.xlsx",
      name: "Descripción del flujo de trabajo geográfico",
      hint: "Para ejecutores y coordinadores técnicos"
    }]
  };
  tramite: EscritorioTramite;

  constructor(
    private menuService: MenuService,
    private store: Store<AppState>,
    private securityService: SecurityService,
    public router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public breadcrumbService: BreadcrumbService,
    public service: RadicarService,
    private adminService: AdminService
  ) {
    const windowKey = "catasia";
    window[windowKey] = this;
    this.subscriptionStore = this.store.select("auth").subscribe(({ user }) => {
      this.usuario = user;
      if (user) {
        service.get("utils/certNotAfter").subscribe(fecha => {
          this.usuario.certNotAfter = fecha[0];
          this.usuario.diasCertNotAfter = fecha[1];
          if (fecha[2]) {
            this.usuario.geo = fecha[2];
          }
          if (fecha[3]) {
            this.usuario.menu = fecha[3];
          }
        });
        this.checkTokenGeo();
      }
    });

    if (AppSettings.getToken()) {
      // this.urlAvatar = environment.API_URL + "users/avatar";
      this.securityService.getInfo().subscribe(
        (user: UserInfoModel) => {
          this.store.dispatch(setUser({ user }));
        },
        (err) => {
          console.log(err);
          this.securityService.logout();
          this.router.navigate(["/login"]);
        }
      );
    } else {
      this.router.navigate(["/login"]);
    }

    tipoArchivosCompatible(this.service).then(tipos => {
      this.archivoTipos = tipos.join(",");
    });
    this.filesize = getFileSizeUpload();

    this.adminService.getOficinasActivas().subscribe((oficinas: any[]) => {
      this.oficinas = oficinas;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionStore?.unsubscribe();
  }

  checkTokenGeo(trigger: () => void = null) {
    if (this.usuario) {
      this.service.getText(trigger ? "usuario/getTokenGeo/renew" : "usuario/getTokenGeo").subscribe(token => {
        this.usuario.urlVisorPredio2 = this.usuario.urlVisorPredio + (this.usuario.urlVisorPredio.includes("?") ? "&" : "?")
          + "tokensgc=" + encodeURIComponent(token) + "&predio=";
        this.usuario.urlVisorTramite2 = this.usuario.urlVisorTramite + (this.usuario.urlVisorTramite.includes("?") ? "&" : "?")
          + "tokensgc=" + encodeURIComponent(token) + "&tramite=";
        if (trigger) {
          trigger();
        }
      });
      clearTimeout(window["checkTokenGeo"]);
      window["checkTokenGeo"] = setTimeout(() => this.checkTokenGeo(), 1000 * 60 * 5);
    }
  }

  getFecha() {
    return new Date().toDateString();
  }

  random(min: number, max: number) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  randomExp(min: number, max: number, weight: number) {
    return (
      min +
      Math.round(
        Math.pow(this.random(0, Math.pow(max - min + 1, weight)), 1 / weight)
      )
    );
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.megaMenuMobileClick) {
      this.megaMenuMobileActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.menuService.reset();
      }

      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }

      this.menuHoverActive = false;
    }

    this.menuClick = false;
    this.topbarItemClick = false;
    this.megaMenuClick = false;
    this.megaMenuMobileClick = false;
    this.rightPanelClick = false;
  }

  onMegaMenuButtonClick(event) {
    this.megaMenuClick = true;
    this.megaMenuActive = !this.megaMenuActive;
    event.preventDefault();
  }

  onMegaMenuClick(event) {
    this.megaMenuClick = true;
    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;

    event.preventDefault();
  }

  onRightPanelClose(event) {
    this.rightPanelActive = false;
    this.rightPanelClick = false;

    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMobileMenuClick = true;
    this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

    event.preventDefault();
  }

  onMegaMenuMobileButtonClick(event) {
    this.megaMenuMobileClick = true;
    this.megaMenuMobileActive = !this.megaMenuMobileActive;

    event.preventDefault();
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isMobile()) {
      this.menuMobileActive = !this.menuMobileActive;
    }

    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.staticMenuActive = !this.staticMenuActive;
    event.preventDefault();
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isHorizontal() {
    return this.horizontalMenu === true;
  }

  isOverlay() {
    return this.horizontalMenu === false;
  }

  isDarkMode() {
    return this.darkMode === true;
  }

  logout() {
    this.securityService.logout();
    this.router.navigate(["/login"]);

    // location.href = "#/login";
  }

  // filtrarUsuario(event: any) {
  //   this.usuariosFiltrados = Object.values(data.usuarios).filter(
  //     (d) => d.nombre.toLowerCase().lastIndexOf(event.query.toLowerCase()) > -1
  //   );
  // }

  public getSolicitanteSolicitudNombre(sol: SolicitudModel = null) {
    if (!sol) {
      sol = this.solicitud;
    }
    return this.getSolicitanteNombre(sol.solicitante);
  }

  public getSolicitanteNombre(sol: SolicitanteModel = null) {
    if (!sol?.nombres) {
      return "";
    }
    return ((sol.nombres || "") + " " + (sol.nombre2 || "") + " " + (sol.apellidos || "")
      + " " + (sol.apellido2 || "")).replace(/\s+/g, ' ').trim();
  }

  desarrollo() {
    return environment["desarrollo"];
  }

  ocultarPredialAnterior() {
    return environment["ocultarPredialAnterior"];
  }

  preguntar(mensaje: string, accept: Function, reject: Function = null) {
    this.confirmationService.confirm({
      key: "appPositionDialog",
      message: mensaje,
      header: "Confirmación requerida",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sí",
      rejectLabel: "No",
      accept: accept,
      reject: reject
    });
  }

  mensaje(value: string, severity: string): void {
    this.messageService.clear();
    this.messageService.add({
      key: "message",
      severity: severity,
      life: 10000,
      detail: value,
    });
  }

  controlarArchivoSeleccionado(event: any) {
    if (event.currentFiles.length === 0) {
      let message = `La extensión del archivo no es valida o supera el tamaño max permitido ${getFileSizeUploadParseMB()}MB`;
      let severity = "error";
      this.mensaje(message, severity);
    }
  }

  descargarUltimoDocumento(id: number) {
    window.open(
      environment.API_URL +
      environment.host.ms_gateway.documentos.documentoDescargarUltimo +
      id +
      "?access_token=" +
      AppSettings.getToken()
    );
  }

  visualizarDocumento(id: number) {
    window.open(
      environment.API_URL +
      "archivo/descargar/" +
      id +
      "?access_token=" +
      AppSettings.getToken()
    );
  }

  toHumanTime(timeMillis: any) {
    if (!timeMillis) {
      return null;
    }
    const time = Number(timeMillis);
    if (time < 1000) {
      return time + " ms";
    }
    if (time < 60000) {
      return (time / 1000.0) + " seg";
    }
    return (time / 60000.0) + " min";
  }

  public environment() {
    return environment;
  }

  labels() {
    return environment.labels;
  }

  getMenu(): Promise<ModuloPermisosModel> {
    return new Promise((resolve, reject) => {
      if (this.usuario) {
        resolve(this.usuario.menu);
      } else {
        const timer = setInterval(() => {
          if (this.usuario) {
            clearInterval(timer);
            resolve(this.usuario.menu);
          }
        }, 80);
      }
    });
  }

  parseFecha(fecha: any) {
    if (typeof fecha == "string" && fecha.length > 10) {
      return fecha.substring(0, 10);
    }
    return fecha;
  }

  makeFecha(fecha: any) {
    if (typeof fecha == "string" && fecha.length >= 10) {
      const partes = fecha.substring(0, 10).split("-");
      return new Date(Number(partes[0]), Number(partes[1]) - 1, Number(partes[2]));
    }
    return fecha;
  }

  abrirGuia(guia: any) {
    window.open("assets/guias/" + guia.url);
  }

  getGuiasGeos() {
    const guias = [];
    if (this.usuario?.admin || this.usuario?.geo) {
      if (this.usuario?.admin || this.usuario.menu?.guias.ejecutor) {
        Array.prototype.push.apply(guias, this.guiasGeos.ejecutor);
      }
      if (this.usuario?.admin || this.usuario.menu?.guias.tecnico) {
        Array.prototype.push.apply(guias, this.guiasGeos.tecnico);
      }
      if (this.usuario?.admin || this.usuario.menu?.guias.ejecutor || this.usuario?.menu?.guias.tecnico) {
        Array.prototype.push.apply(guias, this.guiasGeos.ejecutorYtecnico);
      }
    }
    return guias;
  }

  error(error: any, msg: string) {
    let mensaje: string;
    if (error?.status == 403) {
      mensaje = "No tiene acceso a esta información. Por favor verifique su sesión";
    } else if (error?.status === 0) {
      console.log(error);
      mensaje = "Se encuentran problemas de conexión al servidor. Por favor intente nuevamente en un momento. Si el problema persiste, consulte con el administrador";
    } else if (error.error?.trace?.indexOf('Load balancer does not have available server for client:') > 0 || error.error?.trace?.indexOf("java.net.ConnectException") > 0) {
      mensaje = "Los servicios están abajo en este momento. Por favor intente más tarde. Si el problema persiste, contacte con el administrador";
    } else {
      if (typeof error?.error == "string") {
        try {
          error.error = JSON.parse(error.error);
        } catch (e) { }
      }
      mensaje = error?.error?.fields?.detalle_error ||
        error?.error?.message ||
        error?.message ||
        msg;
    }
    this.mensaje(mensaje, "error");
  }

  setModoRol(item: PermisoInterface) {
    this.usuario = { ...this.usuario, modoRol: item.modoRol };
    sessionStorage.setItem("user.modoRol", item.modoRol);
    sessionStorage.setItem("modulo", item.codigo);
    sessionStorage.setItem("modulo.nombre", item.nombre);
    scrollTo(0, 0);
  }

  log(data: any) {
    console.log(data);
  }

  descargarPlain(text: string, filename: string, type = "text/plain") {
    var a = document.createElement("a");
    a.download = filename;
    a.href = URL.createObjectURL(new Blob([text], { type }));
    a.click();
    if (type == "text/plain") {
      var tab = window.open('about:blank', '_blank');
      tab.document.write("<pre style='style='word-wrap: break-word; white-space: pre-wrap;'>" + text + "</pre>");
      tab.document.close();
    }
  }
}
